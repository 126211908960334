<template>
    <div>
        <div class="header_holder text-center ma">
            <div class="rounder_top  mb-2">
                <div class="card_header ubuntu_b primer">
                    Arhiva narudžbenica
                </div>
                <v-dialog
                                
                                ref="dialog"
                                v-model="modal"
                                :return-value.sync="date"
                                persistent
                                width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                    class="mt-3 date_picker"
                                        v-model="dateFormatted"
                                        label="Pretraži po datumu isporuke"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        solo
                                        @blur="date = parseDate(dateFormatted)"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="date"
                                    first-day-of-week="1"
                                    scrollable
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="modal = false">Odustani</v-btn>
                                    <v-btn text color="primary" @click="$refs.dialog.save(date)">Potvrda</v-btn>
                                </v-date-picker>
                            </v-dialog>
            </div>
        </div>
        <OrderCard
            v-for="order in orders" :key="order.id" v-bind:order="order"
        ></OrderCard>
    </div>
</template>
<script>
import OrderCard from './OrderCard.vue'
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
export default {
    data() {
        return {
            modal:false,
            date: null,
            today: null,
            dateFormatted: null,
            todayFormatted: null,
        }
    },
    components: { OrderCard },
    computed: {
        ...mapGetters({
            orders: 'orders/getOrders',
            userCredentials: 'user/getCredentials',
        }),
        computedDateFormatted () {
            return this.formatDate(this.date)
        },
        computedTodayFormatted () {
            return this.formatDate(this.today)
        },
    },
    watch: {
        date () {
            this.dateFormatted = this.formatDate(this.date)
            this.page = 1
            this.$store.dispatch('orders/getAllOrders', this.date)
        },
        today () {
            this.todayFormatted = this.formatDate(this.today)
        }
    },
    methods: {
        formatDate (date) {
            console.log('date2', this.today);
            console.log('type2',typeof this.today);
            if (!date || typeof date == "object") return null
            const [year, month, day] = date.split('-')
            return `${month}.${day}.${year}`
        },
        parseDate (date) {
            if (!date || typeof date == "object") return null
            const [month, day, year] = date.split('.')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
    },
    created () {
        console.log('usao u created')
        this.today = moment().tz("Europe/Zagreb").toISOString().substring(0, 10)
        this.$store.dispatch('orders/getAllOrders')
    }
}
</script>
<style scoped>
    .back{
        position:absolute;
        width:26px;
        height:26px;
        left:13px;
        top:13px;
    }
    .date_picker{
        width: 300px;
        position: absolute;
        right: -20px;
        top: -30px;
        z-index: 100;
    }
</style>