<template>
    <div class="order_holder primer rounder">
        <v-btn class="close mob" x-small fab color="white" @click="removeItem(order.id)">
            <v-icon small>fas fa-times</v-icon>
        </v-btn>
        <div class="header">
            Narudžbenica #{{order.id}} za {{order.company}}
        </div>
        <div :class="order.date_delivered!==''?'order_dates':''">
            
            <v-row>
                <v-col cols="6" class="text-left">
                    NARUČENO<br>
                    <span>{{ order.date_created | dateParse('YYYY-MM-DD HH:mm:ss') | dateFormat('DD.MM.YYYY. HH:mm') }}</span>
                    <template v-if="order.date_delivered==''"> ZA <span>{{ order.date_delivery | dateParse('YYYY-MM-DD') | dateFormat('DD.MM.YYYY.') }}</span></template>
                </v-col>
                <v-col cols="6" class="text-right">
                    <template v-if="order.date_delivered!==''">ISPORUČENO</template><br>
                    <span v-if="order.date_delivered!==''">{{ order.date_delivered | dateParse('YYYY-MM-DD HH:mm:ss') | dateFormat('DD.MM.YYYY. HH:mm') }}</span>
                </v-col>
            </v-row>
        </div>
        <div class="bottom_order">
            <div class="order_sum">{{ order.suma | currency }}</div>
            <router-link :to="'/order/'+order.id" class="router_right">
                <v-btn color="black" class="add_to_cart_btn rounder">
                    PREGLEDAJ
                </v-btn>
            </router-link>
            <v-btn color="gray" @click="removeItem(order.id)" text class="desktop mr-5 router_right">
                    Obriši
            </v-btn>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            panel: [],
            //todo prebaciti na jedno mesto
        }
    },
    props: ['order'],
    computed: {
        ...mapGetters({
            user: 'user/getUser',
            userCredentials: 'user/getCredentials'
        })
    },
    methods: {
        removeItem (id) {
            confirm('Jeste li sigurni da želite obrisati narudžbu iz arhive?') && this.$store.dispatch('orders/hideOrder', {id})
        },
    },
};
</script>
<style scoped>
    .order_holder{
        box-shadow:0 2px 3px rgba(0,0,0,.07);
        margin-top:20px;
        padding:7px;
        position: relative;
    }
    .close{
        position: absolute;
        right:-10px;
        top:-10px;
    }
    .order_sum {
        float: left !important;
        margin: 10px 90px 10px 10px;
        font-size: 1.1rem;
        font-weight: 500;
    }
    .order_dates{
        background-image:url('/img/strela.png');
        background-position: center center;
        background-repeat: none;
        background-size: 40px;
    }
    .router_right{
        float:right;
        margin-top:10px;
    }
    .order_header{
        width:100%;
        height:60px;
        min-height:60px !important;
        line-height: 60px;
        position:relative;
        padding:10px 10px 10px 60px !important;
        border-bottom:1px solid rgba(0,0,0,.03);
    }
    .product_holder, .package_holder, .bottom_order{
        position:relative;
        width:100%;
        min-height:50px;
        line-height:20px;
        padding:10px 10px 10px 10px;
        border-bottom:1px solid rgb(226, 226, 226);
    }
    .package_holder .product_holder{
        border:none;
        padding:0;
        color:rgb(143, 143, 143);
    }
    .bottom_order{
        height:50px;
        padding-top:15px;
        border:none;
        padding:0 !important;
        border-top:1px solid #000;
    }
    .product_img{
        position:absolute;
        left:0px;
        top:5px;
        width:70px;
        height:70px;
    }
    .product_title{
        min-height:20px;
        line-height:20px;
        position:relative;
    }
    .product_title span{
        position: absolute;
        right: 0;
        text-align: right;
        display: block;
        top: 0;
    }
    .blur{
        position:relative;
    }
    .blur::after{
        content:"";
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
        background: rgba(255,255,255,.5);
    }
    .gray_cycle{
        position:absolute;
        left:18px;
        top:18px;
        width:24px;
        height:24px;
        border-radius:50%;
        background:#f7f7f7;
    }
    .item_icon, .bottom_order_icon{
        position:absolute;
        left:4px;
        top:4px;
        width:16px;
        height:16px;
    }
    .bottom_order_icon{
        left:24px;
        top:21px;
        width:24px;
        height:24px;
    }
    .top{
        bottom:unset;
        top:10px;
    }
</style>>