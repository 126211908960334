<template>
    <v-container>
        <v-row dense>
            <v-col cols="12" class="mb-6">
                <OrderComponent />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import OrderComponent from '../components/OrderComponent.vue'
export default {
    data() {
        return {
        }
    },
    components: { OrderComponent },
}
</script>
